@import "animations";

.footer-wrapper {
	background-color: $bodyColor;
	color: $backgroundColor;
	width: 100vw;
	display: flex;
	flex-direction: column;
	margin-top: 2rem;
}

.subscribe {
	flex-direction: column;
	padding: 2rem;
	margin-top: 1rem;
	border: 1px solid black;

	.separator {
		color: black;
		line-height: 0;
	}

	p {
		color: $backgroundColor;
		line-height: 1rem;
	}

	.section-content {
		height: auto;
	}
}

.subscribe-header {
	font-family: $header-font;
    text-transform: uppercase;
    font-size: 1.5rem;
    font-weight: normal;
    margin: 0;
    margin-bottom: 1rem;
    letter-spacing: 3px;
}

.section-content {
	flex: 1;
	display: flex;
	flex-wrap: wrap;
	flex-direction: column;

	justify-content: space-between;

	a {
		color: white;
		font-family: $header-font;
	}

}

.subscribe-email.email {
	font-family: $header-font;
	width: 75% !important;
}

form {
	background-color: black;
	font-size: 2rem;
	color: white;
}

input {
	color: black;
	width: 25vw;
	height: 1.5rem;
}

@media (max-width: 768px) {
	.tendril {
		padding: 0.5rem;
	}
}

.tendrils {
	.feed-tendril {
		img {
			width: 25px;
			background-color: white;
			filter: invert(1);
		}
	}
	.home-tendril {
		display: flex;
	    flex-wrap: wrap;
	    width: 100%;
	    align-items: center;
	    justify-content: center;
	    flex-direction: column;
	    padding-top: 1rem;
	    border-top: 1rem solid white;
	    border-bottom: 1rem solid white;

		.logo {
			margin-right: 0.5rem;
			flex: 1;
			max-width: 55px;
			max-height: 55px;
		    color: $backgroundColor;
		    background-color: $backgroundColor;
		    filter: invert(1);	
			animation: infiniteRotation 3s infinite linear;
			margin-top: 0;
			margin-bottom: 0;
		}

		.home-tendril-title {
			font-family: $header-font;
			color: $backgroundColor;
			font-size: 2rem;
			line-height: 1;
			margin: 0.5rem 0;
		}
	}
}

@media (min-width: 768px) {
		.subscribe {
			padding: 2rem 0;	
		}

		.footer-wrapper {
			padding: 0 2vw;
		    flex-direction: row;
		    justify-content: space-evenly;
		    align-items: baseline;	
    	}

		.tendrils {
			.home-tendril {
				border: none;
			}
		}

		.subscribe-header {
			font-size: 2rem;
		}

		.subscribe-email.email {
			width: 85% !important;
		}
}
