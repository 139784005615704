@keyframes infiniteRotation {
  from {
    transform: rotate(0deg);
  }
  to {
    transform: rotate(359deg);
  }
}

.infiniteRotate {
  animation: rotation 2s infinite linear;
}

/**
 * ----------------------------------------
 * animation tracking-in-contract-bck-top
 * ----------------------------------------
 */
@keyframes tracking-in-contract-bck-top {
  0% {
    letter-spacing: 1em;
    -webkit-transform: translateZ(400px) translateY(-300px);
            transform: translateZ(400px) translateY(-300px);
    opacity: 0;
  }
  40% {
    opacity: 0.6;
  }
  100% {
    -webkit-transform: translateZ(0) translateY(0);
            transform: translateZ(0) translateY(0);
    opacity: 1;
  }
}
.trackin-animation {
    animation: tracking-in-contract-bck-top 0.75s ease-out both;
}

/**
 * ----------------------------------------
 * animation fade-in-right
 * ----------------------------------------
 */
@keyframes fade-in-right {
  0% {
    -webkit-transform: translateX(50px);
            transform: translateX(50px);
    opacity: 0;
  }
  100% {
    -webkit-transform: translateX(0);
            transform: translateX(0);
    opacity: 1;
  }
}
